<script>
let cities = require('@/helper/city.json');
export default {
  name: "AddressComponent",
  props : {
    maxlength : {
      type : Number
    },
    placeholder : {
      type : String
    },
    value : {
      type : String
    }
  },
  data(){
    return {
      count : 0,
      showCities : false,
      redCount : false,
      selectedCity : null,
      list : [],
      timeout : null,
      content : ""
    }
  },
  mounted(){
    console.log(this.value)
    if(this.value){
      console.log(this.value)
      this.count = this.value.length;
      this.content = this.value;
      this.redCount = this.count >= this.maxlength;
    }
  },
  methods : {
    selectCity(city){
        this.selectedCity = city;
        this.$emit('enter',city);
    },
    blur(){
        setTimeout(() => {
            this.showCities = false;
        }, 5);
    },
    focus(){
        if(this.list.length > 0){
            console.log(this.list.length)
            console.log(this.list)
            this.showCities = true;
        }
    },
    input(ev){
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            let result = []
            for(let city of cities){
                console.log(city.city);
                
                if(new String(city.city).toLowerCase().includes(ev.target.value.toLowerCase()) || new String(city.region).toLowerCase().includes(ev.target.value.toLowerCase())){
                    result.push(city)
                }
            }
            this.list = result;
            if(this.list.length > 0){
                this.showCities = true;
            }
            
        }, 1000);
    }
  }
}
</script>

<template>
  <div class="textarea w-full">
    <span v-if="selectedCity" class="citys">Вы выбрали город: {{ selectedCity }}</span>
    <input  class="ui-input w-full" @blur="blur" @focus="focus()" v-model="content" rows="3" @input="input" :placeholder="placeholder" name="description"/>
    <div class="address-list" v-if="showCities">
        <span @click="selectCity( (address.city) ? address.city : address.region)" class="addres" v-for="address of list" :key="address">{{ (address.city) ? address.city : address.region }}</span>
    </div>
  </div>


</template>

<style scoped>
.textarea{
  position: relative;
}
.citys{
    font-size: 12px !important;
}
.address-list{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-top: 10px;
    border-radius: 10px;
}
.addres{
    font-size: 13px;
    padding: 5px;
    border-bottom: 0.4px solid #ccc;
}
  .symcount{
    position: absolute;

    padding: 3px;
    border-radius: 2px;
    bottom: 15px;
    color: #4F4F4F99;
    font-size: 12px;
    right: 15px;
  }
  .red{
    color: red !important;
  }
</style>