<template>
    <div class="divider"></div>
    <h2>{{$t('pro.brand')}}</h2>
    <div class="pro-plus-content">
        <div :class="`block ${block.size}`" v-for="block of data.blocks" :key="block">
            <img :src="`${$cdn}pro/${block.file}.png`" alt="">
        </div>
    </div>
    <div class="divider"></div>
</template>
<script>
export default {
    name: "ProductBrand",
    props: {
        data : {
            type : String,
        }
    },
    data(){
        return {}
    }
}
</script>
<style scoped>
.pro-plus-content{
    border-radius: 10px;
    overflow: hidden;
}
.small{
    height: 5vw;
}
.medium{
    height: 46.59vw;
}
.big{
    height: 70vw;
}
.block{
    width: 100%
}
.block img{
    height: inherit;
    width: inherit;
    object-fit: contain;
}
h2{
  font-size: 20px;
  color: #4F4F4F;
  font-weight: 500;
}
</style>