<template>
    <div class="full-offer" v-show="show" ref="full">
        <img src="@/assets/icons/close.svg" @click="closeOffer()" style="background-color: rgba(0,0,0,0.1); border-radius: 50%; padding: 4px; position: absolute; right: 20px; top: 20px" height="24" width="24" alt="">
        <p>{{ text }}</p>
        <Button class="w-full mt-2" style="font-size: 13px;" @click="toDM()">Написать заказчику</Button>
        <Button class="w-full mt-2" style="font-size: 13px;" @click="share()" v-if="$versionAPI >= 8" variant="dark" >Поделиться</Button>
    </div>
    <div class="offer">
        <p>{{ sliceText() }}</p>
        <Button class="w-full mt-2" style="font-size: 13px;" @click="showOffer()">Подробнее</Button>
    </div>
</template>
<script>
import { initUtils } from '@telegram-apps/sdk';
import Button from '../Button.vue';

    
    export default{
        components: {
            Button
        },
        name : "OfferComponent",
        data(){
            return {
                showText : "",
                show : false,
            }
        },
        props : {
            contact : {
                type : String,
            },
            id : {
                type : String,
            },
            text : {
                type : String,
            }
        },
        methods : {
            sliceText(){
                if(this.text.length > 100){
                    return this.text.slice(0,100) + '...';
                }
                else{
                    return this.text;
                }
            },
            showOffer(){
                this.show = true;
                setTimeout(() => {
                    this.$refs.full.style.scale = "1";    
                }, 10);
            },
            closeOffer(){
                this.$refs.full.style.scale = "0";   
                setTimeout(() => {
                    this.show = false
                }, 500);
            },
            toDM(){
                let utils = initUtils();
                let contact = this.contact;
                contact = contact.replaceAll(' ','').replaceAll('@','');
                utils.openTelegramLink("https://t.me/" + contact);
            },
            async share(){
                let prepared_id = await this.$api.get('telegram/shareOffer/' + this.$store.state.userInfo.id + '/' + this.id);
                this.$gram.WebApp.shareMessage(prepared_id.id);
            }
        }
    }
</script>
<style scoped>
.offer{
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}
p{
    font-size: 13px;
}
.full-offer{
    position: absolute;
    top: 10%;
    padding: 20px;
    transition: .2s linear 0s;
    transform-origin: center center;
    width: 90vw;
    left: calc(50% - 45vw);
    background-color: #fff;
    scale: 0;
    border-radius: 10px;
}
</style>