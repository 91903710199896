<script>

import NavigationComponent from "@/components/Navigation.vue";
import ShopAvatar from "@/components/Shops/Includes/ShopAvatar.vue";
import ShopName from "@/components/Shops/Includes/ShopName.vue";
import ButtonUI from "@/components/Button.vue";
import ShopSlogan from "@/components/Shops/Includes/ShopSlogan.vue";

import ProductInShop from "@/components/Products/ProductInShop.vue";
import {initUtils} from "@telegram-apps/sdk";
import ShopDescription from "@/components/Shops/Includes/ShopDescription.vue";

export default {
  name: "ShopsView",
  components: {ProductInShop, ShopSlogan, ButtonUI, ShopName, ShopAvatar, NavigationComponent, ShopDescription},
  data(){
    return {
      loading: true,
      utils : initUtils(),
      shopData : {},
      products : [],
    }
  },
  async mounted() {
    let shop = await this.$api.get('shops/view/' + this.$route.params.id)
    let products;
    if(shop.name){
      products = await this.$api.get('shops/view/' + this.$route.params.id + '/products');
    }
    else{
      this.$router.push('/home');
    }
    this.products = products;
    this.shopData = shop;
    this.loading = false;
  },
  methods : {
    getAddText(){
      let cat = this.shopData.category_slug;
      if(cat == "market" || cat == "handmades"){
        return 'categories.add_to_market'
      }
      else if(cat == "desk"){
        return 'categories.add_to_desk'
      }
      else{
        return 'categories.add_to_else'
      }
    },
    share(){
      this.utils.shareURL('t.me/Tagito_bot/Tagito?startapp=place_shop' + this.$route.params.id, this.$t('shop.share_page',[this.shopData.name]));
    }
  }
}
</script>

<template>
<div class="page">
  <div class="layout">
    
      <div class="header">
        <div class="container">
          <div class="shop-header">
      <!-- <div class="mini-button" @click="$router.go(-1)">
        <img src="@/assets/icons/arrow_left.svg" alt="">
      </div> -->
      <div></div>
      <div class="right_header">
        <div class="mini-button" @click="share()">
          <img src="@/assets/icons/share2.svg" alt="">
        </div>
        <div class="mini-button" @click="$router.push(`/shops/edit/${$route.params.id}`)"  v-if="Number(shopData.owner) == Number($store.state.userInfo.id)">
          <img src="@/assets/icons/settings.svg" alt="">
        </div>
      </div>
        </div>
      

    </div>
        <ShopAvatar :skeleton="loading" :file="shopData.logo"></ShopAvatar>
        <ShopName :skeleton="loading" :text="shopData.name"></ShopName>
        <ShopSlogan v-if="shopData.slogan" :skeleton="loading" :text="shopData.slogan"></ShopSlogan>
        <ShopDescription v-if="shopData.description" :skeleton="loading" :text="shopData.description"></ShopDescription>
      </div>
  </div>
  <div class="content" v-if="!loading">
    <div class="product-container" v-if="products.length > 0">
      <ProductInShop v-for="product of products" :data="product" :key="product._id"></ProductInShop>
    </div>
    <div v-else>
      <span class="text-center w-full block">{{$t("shop.products_not_found")}}</span>
    </div>
  </div>
  <div class="shop-footer" v-if="Number(shopData.owner) != Number($store.state.userInfo.id) && !loading && products.length > 0">
    <span class="shop-city">{{$t("shop.city")}}: {{shopData.city}}</span>
  </div>
  <div class="div" v-else></div>
</div>
 
  <NavigationComponent v-if="Number(shopData.owner) == Number($store.state.userInfo.id)" btns-count="1">
    <ButtonUI class="w-full" @click="$router.push('/products/create/first?cat=' + shopData.category + '&shop=' + shopData._id + '&from=' + $route.query.from)">{{$t(getAddText())}}</ButtonUI>
  </NavigationComponent>
  <NavigationComponent v-else></NavigationComponent>
</template>

<style scoped>
  .shop-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .page{
    padding-top: 0px;
    height: max-content;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .shop-city{
  background-color: #FFFFFF66;
  padding: 15px;
  border-radius: 15px;
  font-size: 12px;
  color: #2D2D2DB2;
  text-align: center;
  width: 300px;
}
  .right_header{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .content{
    margin-top: 40px;
    padding-bottom: 100px
  }
  .layout{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .header{
    display: flex;
    gap: 10px;
    padding-top: 10px;
    flex-direction: column;
    background-color: #D9D9D9;
    padding-bottom: 10px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    align-items: center;
  }
  .shop-footer{
    display: flex;
    gap: 10px;
    padding-top: 10px;
    flex-direction: column;
    background-color: #D9D9D9;
    padding-bottom: 10px;
    height: 100px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .mini-button{
    width: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    background: #00000080;
  }
</style>