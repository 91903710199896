<script>
import NavigationComponent from "@/components/Navigation.vue";
import ButtonUI from "@/components/Button.vue";


export default {
  name: "NotFound",
  components: {ButtonUI, NavigationComponent}
}
</script>

<template>
  <div class="page">
    <h1>{{$t('error_page.enter_laiter')}}</h1>
    <span>{{$t('error_page.message')}}</span>
    <ButtonUI @click="$router.go(-1)">{{ $t("error_page.back") }}</ButtonUI>
  </div>
  <NavigationComponent></NavigationComponent>
</template>

<style scoped>
  h1{
    font-weight: 600;
    font-size: 18px;
  }
  .page{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }
</style>