<script>
import PageBadge from "@/components/PageBadge.vue";
import Navigation from "@/components/Navigation.vue";
import ButtonUI from "@/components/Button.vue";
import FileUploader from "@/components/FileUploader.vue";
import ProPlus from "@/components/ProPlus/ProPlusForm.vue";
import TextareaComponent from "@/components/Textarea.vue";
let axios = require("axios");
export default {
  name: "CreateProduct",
  components: {TextareaComponent, FileUploader, ButtonUI, Navigation, PageBadge,ProPlus},
  data(){
    return {
      category : null,
      name : "",
      price : "",
      loading : false,
      proformopenned : false,
      probanners : [],
      description : "",
      photos :  [],
    }
  },
  methods:{
    hideMobileKeyboard(ev){
      ev.target.blur();
    },
    toFile(ev){
      this.photos.push({f : ev[0], s : URL.createObjectURL(ev[0])});
    },
    pro(){

    },
    async create(){

      let name = this.name;
      let price = this.price;
      let description = this.description;
      let category = this.$route.query.cat;
      let files = this.photos;
      if(name === ""){
        return alert(this.$t("product.creation.error_name"))
      }
      if(files.length == 0){
        return alert(this.$t("product.creation.error_files"))
      }

      if(description.length > 1500){
        return alert(this.$t("product.creation.error_length"))
      }

      

      var formData = new FormData();
      formData.append("name",name);
      formData.append("price",price);
      formData.append("description",description);
      formData.append("category",category)

      if(this.probanners.length > 0){
        let banners = this.probanners;
        for(let i in banners){
          formData.append(`pro_banner_${i}_size`,banners[i].size)
          formData.append(`pro_banner_${i}_file`,banners[i].source)
        }
      }

      for(let file of files){
        let compress = file.f
        formData.append("photos", compress);
      }
      formData.append("seller", this.$store.state.userInfo._id);
      this.loading = true;
      let data = await axios.post(this.$api.ssl + this.$api.baseURL + '/products/' + this.$route.query.shop + '/new', formData, {
        headers: {
          'Authorization' : 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      })
      
      if(data.data.status == 200){
        alert(this.$t("product.creation.success_creation"));
        this.$router.push('/shops/view/' + this.$route.query.shop + '?from=' + this.$route.query.from);
      }
      else{
        alert(data.data.message)
      }
      this.loading = false;
    },
    savePro(banners){
      this.probanners = banners;
      console.log(banners);
      this.proformopenned = false;
      
    },
    removePhoto(photo){
      this.photos.splice(this.photos.indexOf(photo),1);
    }

  },
  mounted(){
    if(this.$route.query.cat){
      this.category = this.$route.query.category;
    }
  }
}
</script>

<template>
  <PageBadge v-if="!proformopenned" :back="`/products/create/first?cat=${$route.query.parent}&shop=${$route.query.shop}&from=${$route.query.from}`"  variant="header" :text="$t('product.creation.create_product')"></PageBadge>
  <ProPlus v-if="proformopenned" @cancel="proformopenned = false" @save="savePro" :data="probanners"></ProPlus>
  <div class="page"  v-if="!proformopenned" @click="void(1)">
    

    <div class="container" @click="void(1)">
      <form action="?" class=" flex flex-col gap-2 " @submit.prevent @click="void(1)">


        <label for="">
          <span class="label-input">{{$t("product.creation.form.name.title")}}*</span>
          <input v-model="name" type="text" class="ui-input w-full"  @keyup.enter="hideMobileKeyboard" :placeholder="$t('product.creation.form.name.placeholder')" name="name">
        </label>
        <label for="">
          <span class="label-input">{{$t("product.creation.form.description.title")}}</span>
          <TextareaComponent @enter="e => description = e" maxlength="1500" :placeholder='$t("product.creation.form.description.placeholder")'></TextareaComponent>
        </label>
        <span class="label-input">{{$t("product.creation.form.photo.title")}}*</span>
        <div class="images">
          <div class="image" v-for="photo in photos" :key="photo">
            <img :src="photo.s" alt="">
            <div class="remove" @click="removePhoto(photo)">
              <img src="@/assets/icons/remove_photo.svg" alt="">
            </div>
          </div>
          <FileUploader v-if="photos.length < 7" nopreview @file="toFile"></FileUploader>
        </div>
        <label for="">
          <span class="label-input">{{$t("product.creation.form.price.title")}}*</span>
          <input v-model="price" type="text" inputmode="numeric" class="ui-input w-full"  @keyup.enter="hideMobileKeyboard" :placeholder="$t('product.creation.form.price.title')" name="name">
        </label>
        <ButtonUI variant="dark" class="mt-2 w-full" @click="proformopenned = true" >Pro+</ButtonUI>
        <ButtonUI class="mt-2 w-full" @click="create()" :loading="loading">{{$t("product.creation.action")}}</ButtonUI>
      </form>



    </div>
    <Navigation>
    </Navigation>
  </div>
</template>

<style scoped>
.label-input{
  font-size: 15px;
}
label{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.banner{
  background: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  height: 100px;
}
.banner img{
  opacity: 0.5;
}
.banner-text{
  font-size: 13px;
  color: #4F4F4F;
}
.image{
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 100px;
  justify-content: center;
  border-radius: 5px;
  font-size: 15px;
}
.image img{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Обрезка изображения, сохраняя его центр */
  object-position: center;
}
.remove{
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: calc(10px);
}
.remove svg{
  width: 30px;
  height: 30px;
}
.images{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>